import { Member, Message, Thread } from '../../../../../../../types/intercom/thread';
import {
    getMemberName,
} from './member';

export const sortMembers = (members: Array<Member>): Array<Member> => {
    const sortedMembers = Array.from(members);

    sortedMembers.sort((a, b) => {
        const aName = getMemberName(a);
        const bName = getMemberName(b);

        if (aName < bName) {
            return -1;
        }

        if (aName > bName) {
            return 1;
        }

        return 0;
    });

    return sortedMembers;
};

export const sortThreads = (threads: Array<Thread>): Array<Thread> => {
    const sortedThreads = Array.from(threads);

    sortedThreads.sort((a, b) => new Date(b.lastMessageTime || b.creationTime).getTime()
            - new Date(a.lastMessageTime || a.creationTime).getTime());

    return sortedThreads;
};

export const sortMessages = (messages: Array<Message>): Array<Message> => {
    const sortedMessages = Array.from(messages);

    sortedMessages.sort((a, b) => new Date(a.creationTime).getTime() - new Date(b.creationTime).getTime());

    return sortedMessages;
};
