import {Thread} from "../../../../../../../types/intercom/thread";
import {getMemberNames} from "./member";

export const threadSearchFilter = (searchString: string) => (thread: Thread) => {
    const search = searchString.toLowerCase();
    const {members} = thread;

    if (thread.name?.toLowerCase().includes(search)) {
        return true;
    }

    const names = getMemberNames(members);

    return names.some((name) => name.toLowerCase().includes(search));
};