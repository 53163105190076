import { createReducer } from '@reduxjs/toolkit';
import { MAIL } from '../../constants/types';
import { Mail, SmartClientServer } from '../../types/smartClient';
import mergeMails from '../../utils/smart-client/mergeMails';
import { resetState } from '../app/actions';
import { handleLoadData, setServer, SmartClient, handleLoadMailData, handleUpdateReadStatus, setMails, setDeletedMails, clearSearchMails, setMailCount, receivedComments, handleLoadSearchMails } from './actions';

const initialState : SmartClient = {
    server: [],
    fetchedData: false,
};

const updateMail = (currentServer: SmartClientServer, mail: Mail) => {
    const mails = currentServer.emailConnection.values;
    const mailIndex = mails?.findIndex((m: Mail) => m.id === mail.id);
    if (mailIndex >= 0) {
        const findMail = mails[mailIndex];
        mails[mailIndex] = {
            ...findMail,
            ...mail,
        };
    }

    if (currentServer.searchMails?.values) {
        const { values } = currentServer.searchMails;
        const searchMailIndex = values.findIndex((m: Mail) => m.id === mail.id);
        if (searchMailIndex >= 0) {
            const findSearchMail = values[searchMailIndex];
            values[searchMailIndex] = {
                ...findSearchMail,
                ...mail,
            };
        }
    }
};

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setServer, (draft, action) => {
        draft.server = action.payload;
    });

    builder.addCase(handleLoadData.fulfilled, (draft, action) => {
        const {
            me,
            users,
            davidSiteId,
            emailConnection,
        } = action.payload;
        const activeServerIndex = draft.server?.findIndex((s: SmartClientServer) => s.davidSiteId === davidSiteId);

        if (activeServerIndex > -1) {
            const currentServer = draft.server[activeServerIndex];
            currentServer.me = me;
            currentServer.emailConnection = emailConnection;
            currentServer.users = users;
            currentServer.fetchedData = true;
            draft.fetchedData = true;
        }
    });

    builder.addCase(handleLoadData.rejected, (draft) => {
        draft.fetchedData = true;
    });

    builder.addCase(handleLoadMailData.fulfilled, (draft, action) => {
        const { mail, activeDavidSiteId } = action.payload;
        const activeServerIndex = draft.server?.findIndex((s: SmartClientServer) => s.davidSiteId === activeDavidSiteId);
        if (activeServerIndex > -1) {
            const currentServer = draft.server[activeServerIndex];
            updateMail(currentServer, mail);
        }
    });

    builder.addCase(handleUpdateReadStatus.fulfilled, (draft, action) => {
        const { mail, removeMail, activeDavidSiteId } = action.payload;
        const activeServerIndex = draft.server?.findIndex((s: SmartClientServer) => s.davidSiteId === activeDavidSiteId);
        if (activeServerIndex > -1) {
            const currentServer = draft.server[activeServerIndex];
            if (removeMail) {
                currentServer.emailConnection.values = currentServer.emailConnection.values.filter((m: Mail) => m.id !== mail.id);
            } else {
                updateMail(currentServer, mail);
            }
        }
    });

    builder.addCase(setMails.fulfilled, (draft, action) => {
        const {
            emailConnection,
            activeDavidSiteId,
            selectedItem,
            messageSearchString,
        } = action.payload;

        const activeServerIndex = draft.server?.findIndex((s : SmartClientServer) => s.davidSiteId === activeDavidSiteId);
        if (activeServerIndex > -1) {
            const currentServer = draft.server[activeServerIndex];
            if (currentServer.emailConnection) {
                const mails = currentServer.emailConnection.values;
                const newList = mergeMails(mails || [], emailConnection.values || [])?.filter((mail: Mail) => (!mail.isRead || (selectedItem?.type === MAIL && selectedItem.id === mail.id)) && !mail.isDeleted);
                currentServer.emailConnection.values = newList;
                if (emailConnection.lastFetch) {
                    currentServer.emailConnection.lastFetch = emailConnection.lastFetch;
                }

                if (typeof emailConnection.skipToken !== 'undefined') {
                    currentServer.emailConnection.skipToken = emailConnection.skipToken;
                }

                if (typeof emailConnection.count === 'number') {
                    currentServer.emailConnection.count = emailConnection.count;
                }
            }

            if (currentServer.searchMails?.values) {
                const { values } = currentServer.searchMails;
                const newList = mergeMails(values || [], emailConnection.values || [])?.filter((mail: Mail) => (!mail.isRead || (selectedItem?.type === MAIL && selectedItem.id === mail.id)) && !mail.isDeleted);
                const lowerCaseSearchValue = (messageSearchString || '').toLowerCase();
                const updatedSearchMails = newList.reduce((filteredMails, mail: Mail) => {
                    if (values.find((currentMail: Mail) => currentMail.id === mail.id)) {
                        filteredMails.push(mail);
                    } else if (lowerCaseSearchValue?.trim()?.length > 0
                        && !mail.isRead && (mail.from?.name?.toLowerCase()
                        ?.includes(lowerCaseSearchValue)
                            || mail.from?.email?.toLowerCase()
                                ?.includes(lowerCaseSearchValue)
                            || mail.subject?.toLowerCase()
                                ?.includes(lowerCaseSearchValue))) {
                        filteredMails.push(mail);
                    }
                    return filteredMails;
                }, []);
                currentServer.searchMails.values = updatedSearchMails;
            }
        }
    });

    builder.addCase(setDeletedMails.fulfilled, (draft, action) => {
        const {
            mails,
            activeDavidSiteId,
        } = action.payload;

        const activeServerIndex = draft.server?.findIndex((s: SmartClientServer) => s.davidSiteId === activeDavidSiteId);
        if (activeServerIndex > -1) {
            const currentServer = draft.server[activeServerIndex];
            currentServer.emailConnection.values = currentServer.emailConnection.values.filter((mail: Mail) => !mails.find((deleted: Mail) => deleted.id === mail.id));

            if (currentServer.searchMails?.values) {
                currentServer.searchMails.values = currentServer.searchMails.values.filter((mail: Mail) => !mails.find((deleted: Mail) => deleted.id === mail.id));
            }
        }
    });

    builder.addCase(clearSearchMails.fulfilled, (draft, action) => {
        const {
            activeDavidSiteId,
        } = action.payload;

        const activeServerIndex = draft.server?.findIndex((s: SmartClientServer) => s.davidSiteId === activeDavidSiteId);
        if (activeServerIndex > -1) {
            const currentServer = draft.server[activeServerIndex];
            currentServer.searchMails = null;
        }
    });

    builder.addCase(setMailCount.fulfilled, (draft, action) => {
        const {
            activeDavidSiteId,
            unreadCount,
        } = action.payload;

        const activeServerIndex = draft.server?.findIndex((s: SmartClientServer) => s.davidSiteId === activeDavidSiteId);
        if (activeServerIndex > -1) {
            const currentServer = draft.server[activeServerIndex];
            if (currentServer.emailConnection) {
                currentServer.emailConnection.count = unreadCount;
            }
        }
    });

    builder.addCase(receivedComments.fulfilled, (draft, action) => {
        const {
            activeDavidSiteId,
            mailId,
            comments,
        } = action.payload;

        const activeServerIndex = draft.server?.findIndex((s: SmartClientServer) => s.davidSiteId === activeDavidSiteId);
        if (activeServerIndex > -1) {
            const currentServer = draft.server[activeServerIndex];
            const mails = currentServer.emailConnection.values;
            const mailIndex = mails?.findIndex((mail: Mail) => mail.id === mailId);
            if (mailIndex >= 0) {
                const findMail = mails[mailIndex];
                let uniqueComments: Mail['comments'];
                if (findMail?.comments) {
                    const mergeComments = [...comments, ...findMail.comments];
                    uniqueComments = [...new Set(mergeComments.map((comment) => comment.id))].map((id) => mergeComments.find((comment) => comment.id === id));
                } else {
                    uniqueComments = comments;
                }
                const updatedMail = {
                    ...findMail,
                    comments: uniqueComments,
                };

                mails[mailIndex] = updatedMail;
            }

            if (currentServer.searchMails?.values) {
                const { values } = currentServer.searchMails;
                const searchMailIndex = values.findIndex((mail: Mail) => mail.id === mailId);
                if (searchMailIndex >= 0) {
                    const findMail = values[searchMailIndex];
                    let uniqueComments: Mail['comments'];
                    if (findMail?.comments) {
                        const mergeComments = [...comments, ...findMail.comments];
                        uniqueComments = [...new Set(mergeComments.map((comment) => comment.id))].map((id) => mergeComments.find((comment) => comment.id === id));
                    } else {
                        uniqueComments = comments;
                    }
                    const updatedMail = {
                        ...findMail,
                        comments: uniqueComments,
                    };
                    values[searchMailIndex] = updatedMail;
                }
            }
        }
    });

    builder.addCase(handleLoadSearchMails.fulfilled, (draft, action) => {
        const {
            activeDavidSiteId,
            searchString,
            emailConnection,
            selectedItem,
        } = action.payload;

        const activeServerIndex = draft.server?.findIndex((s: SmartClientServer) => s.davidSiteId === activeDavidSiteId);
        if (activeServerIndex > -1) {
            const currentServer = draft.server[activeServerIndex];
            const mails = currentServer.searchMails?.values;
            const newList = mergeMails(mails || [], emailConnection.values || [])?.filter((mail: Mail) => (!mail.isRead || (selectedItem?.type === MAIL && selectedItem.id === mail.id)) && !mail.isDeleted);
            if (!currentServer.searchMails) {
                currentServer.searchMails = {};
            }
            currentServer.searchMails.values = newList;
            currentServer.searchMails.query = searchString;
            if (typeof emailConnection.skipToken !== 'undefined') {
                currentServer.searchMails.skipToken = emailConnection.skipToken;
            }
        }
    });

    builder.addCase(resetState, () => initialState);
});

export default reducer;
