import { Thread } from '../../../../../../../types/intercom/thread';
import { getMyMember } from './member';

const getIsThreadRead = (thread: Thread): boolean => {
    const {groupLocationId} = thread;

    if (groupLocationId) {
        return false;
    }

    let userId: number;
    if (typeof window !== 'undefined') {
        userId = typeof chaynsInfo !== 'undefined' ? window.chaynsInfo?.User?.ID : window?.chayns?.env?.user.id;
    }

    if (!userId) {
        return false;
    }
    const userMember = getMyMember(thread, userId);

    if (!userMember){
        return true;
    }

    const {unreadFlag, unreadCount} = userMember;
    return !unreadCount && !unreadFlag;
};

export default getIsThreadRead;
