import { Member, Thread } from '../../../../../../../types/intercom/thread';

export const getMyMember = (thread: Thread, userId: number): Member | undefined => thread.members.find((member) => (member.tobitId === userId) && (!member.deletionTime || member.deletionTime > thread.lastMessageTime));

interface GetMemberNameOptions {
    shouldGetFullName?: boolean;
}

export const getMemberName = (
    member: Member,
    { shouldGetFullName }: GetMemberNameOptions = {},
): string => {
    const memberName = (
        member.name
        || `${member.firstName || ''} ${(shouldGetFullName ? member.lastName : '') || ''}`
    ).trim();

    if (memberName !== '') {
        if (member.reference) {
            return [memberName, member.reference.name].join(' @');
        }
        return memberName;
    }

    if (typeof member.locationId === 'number') {
        return 'unbekannte Site';
        // return getTextstringValue(textstrings.memberNames.unknownSite);
    }
    return 'unbekannte Person';
    // return getTextstringValue(textstrings.memberNames.unknownPerson);
};

export const getMemberById = (members: Member[], id: string): Member | undefined => members.find((member) => member.id === id);

export const getMemberNames = (members: Member[]): string[] => members.map((member) => {
    if (member.name) {
        return member.name;
    }
    if (!member.lastName && !member.firstName) {
        return undefined;
    }
    if (!member.lastName) {
        return member.firstName;
    }
    if (!member.firstName) {
        return member.lastName;
    }
    return `${member.firstName} ${member.lastName}`;
}).filter((name) => !!name);
