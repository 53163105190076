export const MAIL = 'mail';
export const INTERCOM = 'intercom';
export const SITE = 'site';
export const SITE2 = 'site2';
export const EVENT = 'event';

export const FAX = 'fax';
export const VOICE = 'voice';

export const ANSWER_MAIL = 'ANSWER_MAIL';
export const ANSWER_ALL_MAIL = 'ANSWER_ALL_MAIL';
export const FORWARD_MAIL = 'FORWARD_MAIL';
export const EDIT_MAIL = 'EDIT_MAIL';
export const NEW_MAIL = 'NEW_MAIL';
export const DISTRIBUTE = 'DISTRIBUTE';
export const QUOTE_COMMENT = 'QUOTE_COMMENT';

export const MESSAGES = 'messages';
export const SITES = 'sites';
export const NEWS = 'news';

export const INBOX = 'inbox';
export const CALENDAR = 'calendar';
