import { loadModule } from 'chayns-api';
import { ChatFunctions } from '../types/intercom/chat';
import { ExternalModule } from '../types/module';
import { IntercomFunctionModule } from '../constants/defaults';

export const getFunctionModule = <FunctionType>(module: ExternalModule) => loadModule(module.scope, module.module, module.url) as Promise<{
    infocenterFunctions: FunctionType;
}>;

export const getChatFunctions = async () => (await getFunctionModule<ChatFunctions>(IntercomFunctionModule)).infocenterFunctions;
