import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import addMinutes from 'date-fns/addMinutes';
import addYears from 'date-fns/addYears';

import { SET_SERVER } from './types';
import { EmailConnection, Mail, ScComment, SmartClientServer } from '../../types/smartClient';
import { loadData, loadMailData, loadSearchMails } from '../../api/smart-client/get/graphQuerys';
import { getActiveServerState } from './selector';
import { RootState } from '../rootReducer';
import { patchMail } from '../../api/smart-client/patch/patchMail';
import { MAIL } from '../../constants/types';
import mergeMails from '../../utils/smart-client/mergeMails';
import { setIsSearchingMessages, setSelectedItem } from '../app/actions';
import { postResubmit } from '../../api/smart-client/post/postResubmit';
import { deleteMail } from '../../api/smart-client/delete/deleteMail';
import { postDistribute } from '../../api/smart-client/post/postDistribute';

export type SmartClient = {
    server: Array<SmartClientServer>,
    fetchedData: boolean,
};

export const handleLoadData = createAsyncThunk(
    'smartClient/handleLoadData',
    async (activeServer: SmartClientServer) => {
        const {
            endpoint,
            davidSiteId,
        } = activeServer;
        const { status, result } = await loadData(endpoint, 'isRead eq 0');
        if (status === 200 && result?.data) {
            const {
                emailConnection,
                me,
                users,
            } = result.data;
            return {
                emailConnection,
                me,
                users,
                davidSiteId,
            };
        }
        throw new Error('Failed to load server data');
    },
);

export const handleLoadMailData = createAsyncThunk(
    'smartClient/handleLoadMailData',
    async ({ mailId, endpoint } : { mailId: string, endpoint: string }, thunkApi) => {
        const state = thunkApi.getState() as RootState;

        const { status, result } = await loadMailData(endpoint, mailId);
        if (status === 200 && result?.data?.email) {
            return {
                mail: { ...result.data.email, fetchedData: true },
                activeDavidSiteId: state.app.activeDavidSiteId,
            };
        }
        throw new Error('Failed to load mail data');
    },
);

export const handleUpdateReadStatus = createAsyncThunk(
    'smartClient/handleUpdateReadStatus',
    async (mail: Mail, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const activeServer = getActiveServerState(state);
        const { selectedItem, activeDavidSiteId } = state.app;
        const { status } = await patchMail(activeServer.endpoint, mail.id, { isRead: mail.isRead });

        if (status === 204) {
            const removeMail = mail.isRead && (selectedItem?.type !== MAIL || selectedItem?.id !== mail.id);
            return {
                mail,
                removeMail,
                activeDavidSiteId,
            };
        }
        throw new Error('Failed to update read status');
    },
);
export const setMails = createAsyncThunk(
    'smartClient/setMails',
    async (data: { emailConnection: EmailConnection }, thunkApi) => {
        const {
            emailConnection,
        } = data;
        const state = thunkApi.getState() as RootState;
        const { smartClient } = state;
        const { selectedItem, activeDavidSiteId, messageSearchString } = state.app;

        const activeServerIndex = smartClient.server?.findIndex((s : SmartClientServer) => s.davidSiteId === activeDavidSiteId);

        if (activeServerIndex > -1) {
            const currentServer = smartClient.server[activeServerIndex];
            if (currentServer.emailConnection) {
                const newList = mergeMails(currentServer.emailConnection.values || [], emailConnection.values || [])?.filter((mail: Mail) => (!mail.isRead || (selectedItem?.type === MAIL && selectedItem.id === mail.id)) && !mail.isDeleted);
                const doesMailExist = newList.find((mail: Mail) => mail.id === selectedItem?.id);

                if (selectedItem?.type === MAIL && !doesMailExist) {
                    thunkApi.dispatch(setSelectedItem(null));
                }
            }
        }

        return {
            ...data,
            messageSearchString,
            selectedItem,
            activeDavidSiteId,
        };
    },
);

export const setDeletedMails = createAsyncThunk(
    'smartClient/setDeletedMails',
    async (mails: Array<Partial<Mail>>, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { selectedItem, activeDavidSiteId } = state.app;

        if (selectedItem?.type === MAIL && mails.find((mail) => mail.id === selectedItem.id)) {
            thunkApi.dispatch(setSelectedItem(null));
        }

        return {
            mails,
            activeDavidSiteId,
        };
    },
);

export const setResubmit = createAsyncThunk(
    'smartClient/setResubmit',
    async (id: string, thunkApi) => {
        const state = thunkApi.getState() as RootState;

        const hourCalculation = 1000 * 60 * 60;
        const dateInHours = Date.now() / hourCalculation;
        const decimalHours = dateInHours - Math.floor(dateInHours);
        const roundToNextHour = (dateInHours * hourCalculation) + hourCalculation - (decimalHours * hourCalculation);

        const data = await chayns.dialog.date({
            dateType: chayns.dialog.dateType.DATE_TIME,
            minDate: addMinutes(new Date(), 1),
            maxDate: addYears(new Date(), 5),
            preSelect: new Date(roundToNextHour),
        }) as number;

        if (data > 0) {
            if (Date.now() < data * 1000) {
                const isoDate = new Date(data * 1000)?.toISOString();
                const server = getActiveServerState(state);
                const { status } = await postResubmit(server.endpoint, id, isoDate);
                if (status === 204) {
                    thunkApi.dispatch(setDeletedMails([{ id }]));
                }
            } else {
                chayns.dialog.alert('', 'Bitte passen Sie den gewählten Zeitpunkt an. Dieser liegt in der Vergangenheit.');
            }
        }

        return null;
    },
);

export const handleDeleteMail = createAsyncThunk(
    'smartClient/handleDeleteMail',
    async ({ id, subject, skipDialog } : { id: string, subject: string, skipDialog: boolean }, thunkApi) => {
        const state = thunkApi.getState() as RootState;

        const {
            textStrings,
        } = state;
        const server = getActiveServerState(state);

        const dialogText = (() => {
            if (subject) {
                return (textStrings?.['txt_chayns_de_delete_mail'] || 'Sind Sie sicher, dass Sie die eMail <b class="notranslate">##SUBJECT##</b> löschen möchten?').replace('##SUBJECT##', subject);
            }
            return textStrings?.['txt_chayns_de_delete_mail_no_subject'] || 'Sind Sie sicher, dass Sie die eMail löschen möchten?';
        })();

        const data = skipDialog ? 1 : await chayns.dialog.confirm('', dialogText, [
            {
                text: textStrings?.['txt_chayns_de_dialog_yes'] || 'Ja',
                buttonType: 1,
            }, {
                text: textStrings?.['txt_chayns_de_dialog_cancel'] || 'Abbrechen',
                buttonType: 0,
            },
        ]);
        if (data === 1) {
            const { status } = await deleteMail(server?.endpoint, id);
            if (status === 204) {
                thunkApi.dispatch(setDeletedMails([{ id }]));
            }
        }

        return null;
    },
);

export const handleDistribute = createAsyncThunk(
    'smartClient/handleDistribute',
    async ({ id, userIds, createCopy } : { id: string, userIds: Array<string>, createCopy: boolean }, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const server = getActiveServerState(state);

        const {
            endpoint,
        } = server;

        const { status } = await postDistribute(endpoint, id, {
            createCopy,
            userIds,
        });

        if (status === 204) {
            if (!createCopy) {
                thunkApi.dispatch(setDeletedMails([{ id }]));
            }
        }
        return null;
    },
);

export const setMailCount = createAsyncThunk(
    'smartClient/setMailCount',
    async (unreadCount: number, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { activeDavidSiteId } = state.app;

        return {
            unreadCount,
            activeDavidSiteId,
        };
    },
);

export const receivedComments = createAsyncThunk(
    'smartClient/receivedComments',
    async ({ comments, mailId } : { comments: Array<ScComment>, mailId: string }, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { activeDavidSiteId } = state.app;

        return {
            comments,
            mailId,
            activeDavidSiteId,
        };
    },
);

export const clearSearchMails = createAsyncThunk(
    'smartClient/clearSearchMails',
    async (_, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { activeDavidSiteId } = state.app;

        return {
            activeDavidSiteId,
        };
    },
);

export const handleLoadSearchMails = createAsyncThunk(
    'smartClient/handleLoadSearchMails',
    async ({ searchString, emailConnection } : { searchString: string, emailConnection?: EmailConnection }, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { activeDavidSiteId, selectedItem } = state.app;

        const server = getActiveServerState(state);
        const {
            endpoint,
        } = server;

        thunkApi.dispatch(clearSearchMails());

        if (emailConnection) {
            return {
                emailConnection,
                searchString,
                activeDavidSiteId,
            };
        }

        const { status, result } = await loadSearchMails(endpoint, searchString);

        thunkApi.dispatch(setIsSearchingMessages(false));

        if (status === 200 && result?.data?.emailConnection) {
            if (selectedItem?.type === MAIL && !result.data.emailConnection.values.find((mail) => mail.id === selectedItem.id)) {
                thunkApi.dispatch(setSelectedItem(null));
            }

            return {
                emailConnection: result.data.emailConnection,
                searchString,
                activeDavidSiteId,
                selectedItem,
            };
        }
        throw new Error('Failed to load search mails');
    },
);

export const setServer = createAction<Array<SmartClientServer>>(SET_SERVER);
