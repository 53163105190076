import { createAction } from '@reduxjs/toolkit';

import { SET_APP_VERSION, SET_ENV } from './types';

export type Env = {
    appVersion?: string | null,
    davidVersion?: string | null,
    isAndroid?: boolean,
    isDavidClient?: boolean,
    isFirefox?: boolean,
    isIOS?: boolean,
    isMobile?: boolean,
    isMobileBrowser?: boolean,
    isMyChaynsApp?: boolean,
    isTablet?: boolean,
    isElectronClient?: boolean,
    electronVersion?: string,
    isDesktop?: boolean,
};

export const setEnv = createAction<Env>(SET_ENV);
export const setAppVersion = createAction<string>(SET_APP_VERSION);
